import React from "react";
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import EmailIcon from "@mui/icons-material/Email";
import contact_us from "../../assets/contact_us.jpg";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";
import ScrollToTopButton from "../../components/ScrollToTopButton";
import WhatsAppButton from "../../components/WhatsAppButton";
import SectionHeader from "../../components/SectionHeader";

const validationSchema = Yup.object({
  name: Yup.string()
    .min(1, "Please enter a name more than 1 character")
    .required("Name is required"),
  email: Yup.string()
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Invalid email address")
    .required("Email is required"),
  phone: Yup.string()
    .matches(/^\d{10}$/, "Invalid phone number")
    .required("Phone is required"),
  message: Yup.string().required("Message is required"),
});

const Contact = () => {
  const theme = useTheme();
  //   const smScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const mdScreen = useMediaQuery(theme.breakpoints.down("md"));
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    message: "",
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await axios.post("/api/contact/submit-form", values);

      if (response.status === 200) {
        toast.success(response.data.message, {
          autoClose: 2000,
        });

        resetForm();
      }
    } catch (error) {
      toast.error("Message Failed!", {
        autoClose: 2000,
      });
      console.error("Error submitting form", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box
      component="section"
      sx={{
        overflow: "hidden",
      }}
    >
      <Container
        style={{
          margin: "80px auto",
          "& .MuiOutlinedInput-root.Mui-focused fieldset": {
            borderColor: "black",
          },
        }}
      >
        <Grid container spacing={3}>
          <Grid item md={12} as="article">
            <Paper
              elevation={3}
              sx={{
                borderRadius: "15px",
                padding: "24px",
              }}
            >
              <SectionHeader
                variant={mdScreen ? "h3" : "h2"}
                textAlign={"center"}
                padding={"20px"}
              >
                Contact us
              </SectionHeader>

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  setValues,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <TextField
                          label="Name"
                          fullWidth
                          required
                          name="name"
                          type="text"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          error={!!touched.name && !!errors.name}
                          helperText={touched.name && errors.name}
                          inputProps={{ style: { fontWeight: "bold" } }}
                          InputLabelProps={{ style: { fontWeight: "bold" } }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Email"
                          fullWidth
                          required
                          name="email"
                          type="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                          error={!!touched.email && !!errors.email}
                          helperText={touched.email && errors.email}
                          inputProps={{ style: { fontWeight: "bold" } }}
                          InputLabelProps={{ style: { fontWeight: "bold" } }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Phone"
                          fullWidth
                          required
                          name="phone"
                          type="text"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.phone}
                          error={!!touched.phone && !!errors.phone}
                          helperText={touched.phone && errors.phone}
                          inputProps={{ style: { fontWeight: "bold" } }}
                          InputLabelProps={{ style: { fontWeight: "bold" } }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          label="Message"
                          multiline
                          rows={4}
                          fullWidth
                          required
                          name="message"
                          type="text"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.message}
                          error={!!touched.message && !!errors.message}
                          helperText={touched.message && errors.message}
                          inputProps={{ style: { fontWeight: "bold" } }}
                          InputLabelProps={{ style: { fontWeight: "bold" } }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          disabled={isSubmitting}
                          sx={{
                            background: "#a77639",
                            color: "#FFF",
                            fontWeight: "bold",
                            "&:hover": {
                              background: "#FFF",
                              color: "#a77639",
                            },
                          }}
                        >
                          {isSubmitting ? (
                            <>
                              <CircularProgress
                                size={20}
                                style={{ marginRight: 10, fontWeight: "bold" }}
                              />
                              Submitting...
                            </>
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Paper>
          </Grid>

          <Grid item md={12} as="article">
            <Paper
              elevation={3}
              sx={{
                borderRadius: "15px",
                padding: "24px",
                display: "flex",
                justifyContent: "center",
                "@media (max-width: 600px)": {
                  flexDirection: "column",
                  alignItems: "center",
                  width: "91vw",
                },
                "@media (min-width: 601px) and (max-width: 900px)": {
                  flexDirection: "column",
                  alignItems: "center",
                  width: "94vw",
                },
              }}
            >
              <Grid
                container
                spacing={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                as="address"
              >
                <Grid item>
                  <HomeIcon sx={{ color: "#a77639" }} />
                </Grid>
                <Grid item sx={{ marginBottom: "15px", textAlign: "center" }}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    39/9, Tirupathi Nagar,
                    <br />
                    Kolathur, Chennai - 600 099.
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Grid item>
                  <PhoneAndroidIcon sx={{ color: "#a77639" }} />
                </Grid>
                <Grid item sx={{ marginBottom: "15px", textAlign: "center" }}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    +91 98404 28762 / +91 95660 98707
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Grid item>
                  <EmailIcon sx={{ color: "#a77639" }} />
                </Grid>
                <Grid item>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    eshwarsignatures@gmail.com
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item md={12} as="figure" padding={0}>
            <img
              style={{
                width: "100%",
                borderRadius: "15px",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
              src={contact_us}
              alt="Meeting Discussion"
            />
          </Grid>
        </Grid>
        {/* Scroll to top button */}
        <ScrollToTopButton />

        {/* WhatsApp Button */}
        {smScreen && <WhatsAppButton />}
      </Container>
    </Box>
  );
};

export default Contact;
