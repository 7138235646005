import {
  Box,
  Button,
  Typography,
  Link,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import logoMobile from "../assets/logo/logoMobile.png";

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // const logoSrc = isMobile ? logoMobile : logo;
  const flexDirection = isMobile ? "column" : "row";
  const imageDiv = isMobile ? "100%" : "250px";
  const imageHeight = isMobile ? "auto" : "70px";

  return (
    <>
      <Box bgcolor="#f2f2f2" color="#333" py={3}>
        {/* Contact Details */}
        <Box
          sx={{
            maxWidth: "1440px",
            margin: "auto",
            display: "flex",
            justifyContent: "space-around",
            flexDirection,
            alignItems: "center",
            p: "10px",
          }}
        >
          <Box
            justifyContent="center"
            alignItems="center"
            sx={{ width: imageDiv, textAlign: "center" }}
          >
            <img
              src={logoMobile}
              alt=""
              style={{ width: "100%", height: imageHeight }}
            />
          </Box>
          <Box textAlign="center" marginTop="auto">
            <Typography variant="body1" fontWeight="bold" paragraph>
              Transform your dream home into reality! Contact us for
              personalized construction solutions and expert assistance. Let's
              build your future together!
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                background: "#a77639",
                color: "#FFF",
                "&:hover": {
                  background: "#FFF",
                  color: "#a77639",
                },
              }}
            >
              <Link
                href="mailto:eshwarsignatures@gmail.com"
                underline="none"
                color="inherit"
                fontWeight="bold"
              >
                Email Us
              </Link>
            </Button>
            <Typography
              variant="body1"
              color="textSecondary"
              mt={2}
              fontWeight="bold"
            >
              Or call us at:{" "}
              <Link
                href="tel:+919840428762"
                color="inherit"
                sx={{ fontWeight: "bold" }}
              >
                +91 98404 28762
              </Link>
              <span> / </span>
              <Link
                href="tel:+919566098707"
                color="inherit"
                sx={{ fontWeight: "bold" }}
              >
                +91 95660 98707
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Copyrights Footer */}
      <Box
        bgcolor="#A77639"
        color="#fff"
        py={3}
        textAlign="center"
        marginTop="auto"
      >
        <Typography sx={{ fontWeight: "bold" }}>
          Copyright © 2023 Eshwar Signatures. All Rights Reserved.
        </Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          Designed By{" "}
          <Link
            href="mailto:kaushikchopra2908@gmail.com"
            color="#000"
            underline="none"
          >
            Kaushik Chopra
          </Link>
        </Typography>
      </Box>
    </>
  );
};

export default Footer;
