// Navbar.js
import { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Menu,
  MenuItem,
  Link,
} from "@mui/material";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logo from "../assets/logo/logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import PhoneIcon from "@mui/icons-material/Phone";

const Topbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [galleryAnchorEl, setGalleryAnchorEl] = useState(null);
  const [servicesAnchorEl, setServicesAnchorEl] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleGalleryClick = (event) => {
    setGalleryAnchorEl(event.currentTarget);
  };

  const handleGalleryClose = () => {
    setGalleryAnchorEl(null);
  };

  const handleGalleryOptionClick = (option) => {
    handleGalleryClose();
    navigate(`/gallery/${option}`);
    handleDrawerClose();
  };

  const handleServiceClick = (event) => {
    setServicesAnchorEl(event.currentTarget);
  };

  const handleServicesClose = () => {
    setServicesAnchorEl(null);
  };

  const handleServicesOptionClick = (option) => {
    handleServicesClose();
    navigate(`/services/${option}`);
    handleDrawerClose();
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -70;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <AppBar position="fixed">
      <Toolbar
        sx={{
          justifyContent: "space-between",
        }}
      >
        {/* Logo */}
        <Typography
          variant="body1"
          component="div"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/")}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src={logo}
              alt="Logo"
              style={{ width: "65px", height: "65px" }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              <span style={{ display: "block" }}>Eshwar Signatures LLP</span>
              <span
                style={{
                  display: "block",
                  fontSize: "10px",
                }}
              >
                Build Right. Build Quality.
              </span>
            </Box>
          </Box>
        </Typography>

        {/* Navigation Links (Visible on Desktop) */}
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {/* Add a normal anchor tag for 'Why Us?' */}
            <Button
              color="inherit"
              component={HashLink}
              scroll={scrollWithOffset}
              to="/#why-us"
              sx={{
                // fontWeight: location.hash === "#why-us" ? "bold" : "normal",
                fontWeight: "bold",
                color: location.hash === "#why-us" ? "#FFC47E" : "inherit",
              }}
            >
              Why Us?
            </Button>
            {/* Add a normal anchor tag for 'Project' */}
            <Button
              color="inherit"
              component={HashLink}
              scroll={scrollWithOffset}
              to="/#projects"
              sx={{
                // fontWeight: location.hash === "#projects" ? "bold" : "normal",
                fontWeight: "bold",
                color: location.hash === "#projects" ? "#FFC47E" : "inherit",
              }}
            >
              Project
            </Button>
            {/* Gallery Dropdown */}
            <Button
              color="inherit"
              onClick={handleGalleryClick}
              sx={{
                // fontWeight: location.pathname.includes("/gallery")
                //   ? "bold"
                //   : "normal",
                fontWeight: "bold",
                color: location.pathname.includes("/gallery")
                  ? "#FFC47E"
                  : "inherit",
              }}
            >
              Gallery
            </Button>
            <Menu
              anchorEl={galleryAnchorEl}
              open={Boolean(galleryAnchorEl)}
              onClose={handleGalleryClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem
                sx={{
                  fontWeight: "bold",
                  color: location.pathname.includes("/gallery/interior")
                    ? "#FFC47E"
                    : "inherit",
                }}
                onClick={() => handleGalleryOptionClick("interior")}
              >
                Interior
              </MenuItem>
              <MenuItem
                sx={{
                  fontWeight: "bold",
                  color: location.pathname.includes("/gallery/exterior")
                    ? "#FFC47E"
                    : "inherit",
                }}
                onClick={() => handleGalleryOptionClick("exterior")}
              >
                Exterior
              </MenuItem>
            </Menu>
            {/* End Gallery Dropdown */}

            {/* Services Dropdown */}
            <Button
              color="inherit"
              onClick={handleServiceClick}
              sx={{
                // fontWeight: location.pathname.includes("/services")
                //   ? "bold"
                //   : "normal",
                fontWeight: "bold",
                color: location.pathname.includes("/services")
                  ? "#FFC47E"
                  : "inherit",
              }}
            >
              Services
            </Button>
            <Menu
              anchorEl={servicesAnchorEl}
              open={Boolean(servicesAnchorEl)}
              onClose={handleServicesClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem
                sx={{
                  fontWeight: "bold",
                  color: location.pathname.includes(
                    "/services/property-management"
                  )
                    ? "#FFC47E"
                    : "inherit",
                }}
                onClick={() => handleServicesOptionClick("property-management")}
              >
                Property Management
              </MenuItem>
              <MenuItem
                sx={{
                  fontWeight: "bold",
                  color: location.pathname.includes("/services/approval")
                    ? "#FFC47E"
                    : "inherit",
                }}
                onClick={() => handleServicesOptionClick("approval")}
              >
                CMDA/DTCP Approval
              </MenuItem>
              <MenuItem
                sx={{
                  fontWeight: "bold",
                  color: location.pathname.includes(
                    "/services/waterproofing-work"
                  )
                    ? "#FFC47E"
                    : "inherit",
                }}
                onClick={() => handleServicesOptionClick("waterproofing-work")}
              >
                Waterproofing Work
              </MenuItem>
              <MenuItem
                sx={{
                  fontWeight: "bold",
                  color: location.pathname.includes("/services/renovation")
                    ? "#FFC47E"
                    : "inherit",
                }}
                onClick={() => handleServicesOptionClick("renovation")}
              >
                Home Renovation
              </MenuItem>
            </Menu>
            {/* End Services Dropdown */}

            <Button
              color="inherit"
              component={RouterLink}
              to="/contact"
              sx={{
                fontWeight: "bold",
                color: location.pathname === "/contact" ? "#FFC47E" : "inherit",
              }}
            >
              Contact
            </Button>
          </Box>
        </Box>

        {/* Burger Icon for Mobile */}
        <IconButton
          size="large"
          edge="start"
          color="black"
          aria-label="menu"
          sx={{ display: { md: "none" } }}
        >
          <Link
            href="tel:+919566098707"
            underline="none"
            sx={{ color: "#000" }}
          >
            <PhoneIcon />
          </Link>
          <Box sx={{ ml: 2 }} onClick={handleDrawerOpen}>
            <MenuIcon />
          </Box>
        </IconButton>

        {/* Drawer for Mobile Navigation */}
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={handleDrawerClose}
          ModalProps={{ keepMounted: true }}
          sx={{
            "& .MuiDrawer-paper": {
              width: "300px", // Adjust the width as needed
            },
            "& .MuiTypography-root": {
              textAlign: "center",
            },
          }}
        >
          {/* Close button in the drawer */}
          <IconButton
            color="inherit"
            aria-label="close drawer"
            onClick={handleDrawerClose}
            sx={{ alignSelf: "flex-end", marginRight: 1 }}
          >
            <CloseIcon />
          </IconButton>

          <List
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%", // Ensure the List takes up full height
              fontWeight: "700",
            }}
          >
            {/* HashLink from React Router Hash Link */}
            {/* Why Us? Link */}
            <ListItem
              button
              component={HashLink}
              onClick={handleDrawerClose}
              scroll={scrollWithOffset}
              to="/#why-us"
            >
              <ListItemText
                primary="Why Us?"
                primaryTypographyProps={{
                  fontWeight: "bold",
                  color: location.hash === "#why-us" ? "#FFC47E" : "inherit",
                }}
              />
            </ListItem>

            {/* Project Link */}
            <ListItem
              button
              component={HashLink}
              onClick={handleDrawerClose}
              scroll={scrollWithOffset}
              to="/#projects"
            >
              <ListItemText
                primary="Project"
                primaryTypographyProps={{
                  fontWeight: "bold",
                  color: location.hash === "#projects" ? "#FFC47E" : "inherit",
                }}
              />
            </ListItem>

            {/* RouterLink from React Router DOM */}
            {/* Gallery Dropdown */}
            <ListItem button onClick={handleGalleryClick}>
              <ListItemText
                primary="Gallery"
                primaryTypographyProps={{
                  fontWeight: "bold",
                  color: location.pathname.includes("/gallery")
                    ? "#FFC47E"
                    : "inherit",
                }}
              />
            </ListItem>
            {/* End Gallery Dropdown */}

            <ListItem button onClick={handleServiceClick}>
              <ListItemText
                primary="Services"
                primaryTypographyProps={{
                  fontWeight: "bold",
                  color: location.pathname.includes("/services")
                    ? "#FFC47E"
                    : "inherit",
                }}
              />
            </ListItem>
            <ListItem
              button
              component={RouterLink}
              to="/contact"
              onClick={handleDrawerClose}
            >
              <ListItemText
                primary="Contact"
                primaryTypographyProps={{
                  fontWeight: "bold",
                  color:
                    location.pathname === "/contact" ? "#FFC47E" : "inherit",
                }}
              />
            </ListItem>
          </List>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
