import React from "react";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Divider,
} from "@mui/material";
import HouseIcon from "@mui/icons-material/House";
import SectionHeader from "../../../components/SectionHeader";
import House from "../../../assets/projectmanagement/2.jpg";
import Footer from "../../../components/Footer";
import ScrollToTopButton from "../../../components/ScrollToTopButton";
import WhatsAppButton from "../../../components/WhatsAppButton";

import { RiDoubleQuotesL } from "react-icons/ri";
import { RiDoubleQuotesR } from "react-icons/ri";

const PropertyManagement = () => {
  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.down("md"));
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const services = [
    "Property maintance and upkeep",
    "Rent collection and financial reporting",
    "Tenant screening and management",
    "Legal and documentation support",
    "Emergency response and repairs",
    "Plot monitoring",
  ];

  const whyUs = [
    {
      title: "Expericence",
      description:
        "With extensive experience in property management, our team boasts the requisite knowledge and expertise to effectively manage all facets of your property.",
    },
    {
      title: "NRI management services",
      description:
        "Overseeing property investments from abroad can present challenges. Our specialization in NRI property management streamlines the process, providing you with convenience in managing your assets remotely.",
    },
    {
      title: "Transparent communication",
      description:
        "At our core, we advocate for open and transparent communication with our clients. Rest assured, you will consistently receive updates on your property's status, fostering a relationship built on trust and clarity.",
    },
  ];

  const howWeWork = [
    {
      title: "Regular Site Visits",
      description:
        "We conduct frequent site visits to ensure the ongoing maintenance and upkeep of your property.",
    },
    {
      title: "Annual Maintenance Work with Terms and Conditions",
      description:
        "We perform annual maintenance work, adhering to specified terms and conditions to ensure your property remains in optimal condition.",
    },
    {
      title: "Rent Collection and Deposits into Owners' Accounts",
      description:
        "We handle the collection of rent and ensure prompt deposits into owners' accounts, providing a seamless financial process.",
    },
    {
      title: "Tax Handling",
      description:
        "We take care of all tax-related matters, ensuring compliance with regulations and timely payments.",
    },
    {
      title: "Owner Representation",
      description:
        "Acting as representatives of property owners, we handle all necessary interactions and negotiations, providing comprehensive support.",
    },
  ];

  return (
    <Box
      component="section"
      sx={{
        marginTop: "70px",
      }}
    >
      <Box
        sx={{
          width: "100vw",
          height: "500px",
        }}
      >
        <img
          src={House}
          alt="A house in night view"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Box>

      <Box
        component="article"
        sx={{
          margin: "50px 20px",

          "@media (min-width: 600px)": {
            margin: "50px 40px",
          },
          "@media (min-width: 960px)": {
            margin: "40px auto",
            maxWidth: "960px",
          },
          "@media (min-width: 1450px)": {
            margin: "50px auto",
            maxWidth: "1440px",
          },
        }}
      >
        <SectionHeader
          variant={mdScreen ? "h3" : "h2"}
          textAlign={"center"}
          padding={"20px"}
        >
          Property Management
        </SectionHeader>

        <Typography
          variant="h6"
          sx={{ textAlign: "center", fontWeight: "bold" }}
          paragraph
        >
          Welcome to Eshwar Signatures property management services
        </Typography>

        <Typography
          variant="h4"
          sx={{ textAlign: "center", fontWeight: "bold" }}
        >
          <RiDoubleQuotesL />
          Experience Confidence with Our Property Management Services
          <RiDoubleQuotesR />
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Divider sx={{ width: "5%", border: "1px solid", bgcolor: "#000" }} />
          <HouseIcon
            sx={{ fontSize: 24, margin: "0 10px", color: "#A77639" }}
          />
          <Divider sx={{ width: "5%", border: "1px solid", bgcolor: "#000" }} />
        </Box>

        {/* Introduction starts */}
        <Box sx={{ paddingBotton: "20px" }}>
          <SectionHeader
            variant={mdScreen ? "h4" : "h3"}
            textAlign={"center"}
            padding={0}
          >
            Introduction
          </SectionHeader>

          <Typography
            variant="body1"
            paragraph
            sx={{ paddingTop: "20px", fontWeight: "bold" }}
          >
            Our dedicated Property Management Division, a specialized branch
            established by Eshwar Signatures, is committed to catering to the
            unique needs of NRIs and Persons of Indian Origin residing outside
            these cities but investing in properties within the region. We
            understand the significance of maintaining and managing your assets,
            even from afar.
          </Typography>

          <Typography variant="body1" paragraph sx={{ fontWeight: "bold" }}>
            Are you seeking premium property management solutions in Chennai,
            Trichy, or Coimbatore? Look no further! At Eshwar Signatures, we
            take pride in offering comprehensive and professional property
            management services tailored to your specific requirements.
          </Typography>

          <Typography variant="body1" paragraph sx={{ fontWeight: "bold" }}>
            Whether you're an NRI seeking seamless property management or a PIO
            individual in search of reliable assistance, we're here to provide
            unparalleled support. With a solid reputation for excellence and a
            dedication to serving our clients, we stand ready to assist you
            every step of the way."
          </Typography>
        </Box>
        {/* Introduction Ends */}

        {/* Property Management Services Starts */}
        <Box sx={{ paddingTop: "20px" }}>
          {" "}
          <SectionHeader
            variant={mdScreen ? "h4" : "h3"}
            textAlign={"Left"}
            padding={0}
          >
            Our Property Management Services
          </SectionHeader>
          <Typography
            variant="body1"
            paragraph
            sx={{ paddingTop: "20px", fontWeight: "bold" }}
          >
            Besides our expansive coverage and personalized solutions, Eshwar
            Signatures extends a diverse range of property management services
            to cater to your multifaceted needs. This includes:
          </Typography>
          <ol style={{ fontWeight: "bold" }}>
            {services.map((service) => (
              <li>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontWeight: "bold" }}
                >
                  {service}
                </Typography>
              </li>
            ))}
          </ol>
          <Typography variant="body1" paragraph sx={{ fontWeight: "bold" }}>
            With our all-encompassing services, rest assured as we handle every
            aspect of your property management needs, granting you peace of
            mind.
          </Typography>
        </Box>
        {/* Property Management Services Ends */}
      </Box>

      {/* Why Us Starts */}
      <Box
        sx={{
          paddingY: "20px",
          backgroundColor: "#A77639",
          color: "#fff",
          height: "100%",
        }}
      >
        <Box
          component="article"
          sx={{
            margin: "0 20px",
            "@media (min-width: 960px)": {
              margin: "20px auto",
              maxWidth: "960px",
            },
            "@media (min-width: 1450px)": {
              margin: "20px auto",
              maxWidth: "1440px",
            },
          }}
        >
          <SectionHeader
            variant={mdScreen ? "h4" : "h3"}
            textAlign={"center"}
            padding={"0 0 20px 0"}
            color={"#fff"}
          >
            Why Choose Us?
          </SectionHeader>
          {whyUs.map((value) => (
            <>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "bold",
                  color: "#000",
                  paddingBottom: "10px",
                }}
              >
                {value.title}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontWeight: "bold", paddingBottom: "10px" }}
              >
                {value.description}
              </Typography>
            </>
          ))}
        </Box>
      </Box>
      {/* Why Us Ends */}

      {/* How we work Starts */}
      <Box
        component="article"
        sx={{
          margin: "10px 20px",
          "@media (min-width: 960px)": {
            margin: "10px auto",
            maxWidth: "960px",
          },
          "@media (min-width: 1450px)": {
            margin: "10px auto",
            maxWidth: "1440px",
          },
        }}
      >
        <Box sx={{ paddingTop: "20px" }}>
          {" "}
          <SectionHeader
            variant={mdScreen ? "h4" : "h3"}
            textAlign={"Left"}
            padding={"0 0 20px 0"}
          >
            How we work?
          </SectionHeader>
          <ol style={{ fontWeight: "bold" }}>
            {howWeWork.map((value, index) => (
              <li key={index}>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    color: "#A77639",
                    paddingBottom: "10px",
                  }}
                >
                  {value.title}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontWeight: "bold", paddingBottom: "10px" }}
                >
                  {value.description}
                </Typography>
              </li>
            ))}
          </ol>
        </Box>
      </Box>
      {/* How we work Ends */}
      <Footer />

      {/* Scroll to top button */}
      <ScrollToTopButton />

      {/* WhatsApp button */}
      {smScreen && <WhatsAppButton />}
    </Box>
  );
};

export default PropertyManagement;
