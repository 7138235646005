import { Box, useMediaQuery, useTheme } from "@mui/material";
import HeroSlider from "../../components/HeroSlider";
import WhyUs from "../WhyUs";
import Projects from "../Projects";
import Footer from "../../components/Footer";
import ScrollToTopButton from "../../components/ScrollToTopButton";
import WhatsAppButton from "../../components/WhatsAppButton";

const Home = () => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        marginTop: "70px",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      {/* Hero Images Carousel */}
      <section id="hero-slider">
        <HeroSlider />
      </section>

      {/* About Section */}
      <section id="why-us">
        <WhyUs />
      </section>

      {/* Projects Section */}
      <section id="projects">
        <Projects />
      </section>

      <Footer />

      {/* Scroll to top button */}
      <ScrollToTopButton />

      {/* WhatsApp button */}
      {smScreen && <WhatsAppButton />}
    </Box>
  );
};

export default Home;
