import { forwardRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Button,
  DialogActions,
  useTheme,
  useMediaQuery,
  Slide,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";

const Transition = forwardRef(function Transition(props, ref) {
  const theme = useTheme();
  return (
    <Slide
      direction="up"
      easing={{
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
      }}
      ref={ref}
      {...props}
    />
  );
});

const ProjectDetail = ({ open, onClose, project }) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [swiper, setSwiper] = useState(null);

  const handleSwiper = (swiper) => {
    setSwiper(swiper);
  };

  const handleClose = () => {
    onClose();
    // Reset Swiper to the first slide when closing the dialog
    if (swiper) {
      swiper.slideTo(0);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
      TransitionComponent={Transition}
      sx={{
        "& .swiper": {
          paddingTop: "35px",
          paddingBottom: "35px",
        },
        "& .swiper-slide": {
          width: "350px",
        },
        "& .swiper-slide img": {
          display: "block",
          width: "100%",
          maxHeight: "75vh",
          objectFit: "contain",
        },
      }}
    >
      <DialogActions>
        <Button
          sx={{
            "&:hover": {
              backgroundColor: "#000",
              color: "#fff",
            },
          }}
          onClick={handleClose}
          color="secondary"
        >
          Close
        </Button>
      </DialogActions>
      <DialogContent
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 0,
        }}
      >
        <Box
          display="flex"
          flexDirection={smallScreen ? "column" : "row"}
          alignItems="center"
        >
          {/* Swiper for Images */}
          <Box
            maxWidth={smallScreen ? "100vw" : "850px"}
            marginRight={smallScreen ? null : 2}
          >
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              onSwiper={handleSwiper}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              style={{
                "--swiper-navigation-color": "#f0f0f0",
                "--swiper-pagination-color": "#f0f0f0",
              }}
            >
              {project.images.map((image, index) => (
                <SwiperSlide key={index}>
                  <Link to={image} target="_blank" rel="noopener noreferrer">
                    <img src={image} alt={`Project img ${index + 1}`} />
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>

          {/* Project Details */}
          <Box
            sx={{
              marginLeft: smallScreen ? 0 : 4,
              textAlign: smallScreen ? "center" : "left",
            }}
          >
            <Typography
              variant={smallScreen ? "h3" : "h2"}
              mb={2}
              sx={{ color: "#A77639", fontWeight: "bold" }}
            >
              {project.type}
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontWeight: 500 }}>
              <strong>Location:</strong> {project.location}
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontWeight: 500 }}>
              <strong>Facing:</strong> {project.facing}
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontWeight: 500 }}>
              <strong>Build-up Area:</strong> {project.buildUpArea}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ProjectDetail;
