import React, { useState } from "react";
import {
  Card,
  CardActionArea,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import styled from "@emotion/styled";
import Footer from "../../components/Footer";
import { interior } from "../../data/images";
import ScrollToTopButton from "../../components/ScrollToTopButton";
import WhatsAppButton from "../../components/WhatsAppButton";
import SectionHeader from "../../components/SectionHeader";

const CardContainer = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const CardMediaStyled = styled("div")`
  height: 300px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const InteriorGallery = () => {
  const [selectedImage, setSelectedImage] = useState("");
  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.down("md"));
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = (image) => {
    setSelectedImage(image.src);
  };

  const handleClose = () => {
    setSelectedImage("");
  };

  const renderImageGrid = () => {
    return (
      <Grid container spacing={3}>
        {interior.map((image, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
            <CardContainer
              onClick={() => handleClickOpen(image)}
              sx={{
                borderRadius: "15px",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
            >
              <CardActionArea>
                <CardMediaStyled
                  style={{ backgroundImage: `url(${image.src})` }}
                />
              </CardActionArea>
            </CardContainer>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Box
      sx={{
        marginTop: "50px",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Box padding="20px" marginX="20px" sx={{ flex: 1 }}>
        <SectionHeader
          variant={mdScreen ? "h3" : "h2"}
          textAlign={"center"}
          padding={"20px"}
        >
          Interior images
        </SectionHeader>
        {renderImageGrid()}

        {/* Dialog for displaying larger image */}
        <Dialog
          fullScreen={mdScreen}
          maxWidth="lg"
          open={!!selectedImage}
          onClose={handleClose}
        >
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
          <DialogContent>
            <img
              src={selectedImage}
              alt="Selected"
              style={{
                width: "100%",
                maxHeight: "60vh",
                textAlign: "center",
                objectFit: "contain",
              }}
              onClick={handleClose}
            />
          </DialogContent>
        </Dialog>
      </Box>
      <Footer />

      {/* Scroll to top button */}
      <ScrollToTopButton />

      {/* WhatsApp button */}
      {smScreen && <WhatsAppButton />}
    </Box>
  );
};

export default InteriorGallery;
