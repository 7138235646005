import project_1 from "../assets/projects/ponneri_project/project_1.jpg";
import project_2 from "../assets/projects/ponneri_project/project_2.jpg";
import kundrathur from "../assets/projects/kundrathur.jpg";
import srirangas_1 from "../assets/projects/srirangas_arumbakkam/1.jpg";
import srirangas_2 from "../assets/projects/srirangas_arumbakkam/2.jpg";
import srirangas_3 from "../assets/projects/srirangas_arumbakkam/3.jpg";
import srirangam from "../assets/projects/srirangam_trichy/1.jpg";
import karnodai from "../assets/projects/karnodai.jpg";
import choolaimedu from "../assets/projects/choolaimedu/1.jpg";
import yogadatra_1 from "../assets/projects/yogadatra_kolathur/exterior/1.jpg";
import yogadatra_2 from "../assets/projects/yogadatra_kolathur/exterior/2.jpg";
import chidambaram_1 from "../assets/projects/chidambaram/1.jpg";

// Completed Projects
export const completedProjects = [
    {
        images: [project_1],
        type: "Individual Villa",
        location: "Ponneri, Panjetty",
        facing: "South",
        buildUpArea: "2900 sq. ft.",
    },
    {
        images: [project_2],
        type: "Individual Villa",
        location: "Ponneri, Panjetty",
        facing: "North",
        buildUpArea: "2600 sq. ft.",
    },
    {
        images: [srirangam],
        type: "Flats",
        location: "Trichy, Srirangam",
        facing: "North",
        buildUpArea: "5432 sq. ft.",
    },
    {
        images: [srirangas_1, srirangas_2, srirangas_3],
        type: "IT Office Space",
        location: "Arumbakkam, Chennai",
        facing: "East",
        buildUpArea: "4160 sq. ft.",
    },
    {
        images: [karnodai],
        type: "Flats",
        location: "Karnodai",
        facing: "East",
        buildUpArea: "6215 sq. ft.",
    },
    {
        images: [choolaimedu],
        type: "Joint Venture",
        location: "Choolaimedu,Chennai",
        facing: "North",
        buildUpArea: "6800 sq. ft.",
    },
];

// On-Going Projects
export const onGoingProjects = [
    {
        images: [yogadatra_1, yogadatra_2],
        type: "Individual House",
        location: "Kolathur,Chennai",
        facing: "West",
        buildUpArea: "3285 sq. ft.",
    }, {
        images: [kundrathur],
        type: "Individual Villa",
        location: "Kundrathur, Chennai",
        facing: "North",
        buildUpArea: "3714 sq. ft.",
    }, {
        images: [chidambaram_1],
        type: "Individual Villa",
        location: "Chidambaram",
        facing: "East",
        buildUpArea: "4015 sq. ft.",
    },
]