import { useState } from "react";
import ProjectDetail from "./ProjectDetail";

// Project Image
const ProjectImage = ({ project }) => {
  const [openDetail, setOpenDetail] = useState(false);

  const handleOpenDetail = () => {
    setOpenDetail(true);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  return (
    <div className="card">
      <img className="image" src={project.images[0]} alt="project" />
      <div className="button">
        <button onClick={handleOpenDetail}>Project Details</button>
      </div>
      {/* Render the ProjectDetail component conditionally */}
      {openDetail && (
        <ProjectDetail
          open={!!openDetail}
          onClose={handleCloseDetail}
          project={project}
        />
      )}
    </div>
  );
};

export default ProjectImage;
