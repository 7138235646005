import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { onGoingProjects, completedProjects } from "../../data/projects";
import ProjectImage from "../../components/ProjectImage";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
// Custome CSS
import "./Projects.css";
import { useEffect, useRef, useState } from "react";

const Projects = () => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const onGoingProjImg = onGoingProjects.map((project, index) => (
    <SwiperSlide>
      <ProjectImage key={index} project={project} />
    </SwiperSlide>
  ));

  const comProjImg = completedProjects.map((project, index) => (
    <SwiperSlide>
      <ProjectImage key={index} project={project} />
    </SwiperSlide>
  ));

  // Create a ref for the section
  const sectionRef = useRef(null);

  // Use state to track whether the section is in the viewport
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Function to handle intersection observer callback
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    };

    // Create an Intersection Observer
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Adjust the threshold as needed
    });

    // Observe the section
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    // Cleanup the observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []); // Run only once on component mount

  return (
    <Box
      ref={sectionRef}
      sx={{
        marginTop: "20px",
        display: "flex",
        flexDirection: "column",
        "& .swiper": {
          width: " 100%",
          paddingTop: smScreen ? "0" : "50px",
          paddingBottom: "50px",
        },
        "& .swiper-slide": {
          width: "350px",
          maxHeight: "500px",
        },
        "& .swiper-slide img": {
          display: "block",
          width: "100%",
        },
      }}
    >
      {/* On-Going Projects Carousel */}
      <Box id="on-going-projects" sx={{ marginBottom: "20px" }}>
        <Grid container spacing={3}>
          {/* On-Going Projects Text start */}
          <Grid item xs={12} sm={5}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                padding: smScreen ? "20px 40px 0" : "40px",
              }}
            >
              <Box
                textAlign={smScreen ? "center" : "left"}
                className={
                  isVisible ? "animate__animated animate__fadeInLeft" : ""
                }
              >
                <Typography
                  variant={smScreen ? "h3" : "h2"}
                  color={"#A77639"}
                  marginBottom="10px"
                  sx={{ fontWeight: "bold" }}
                >
                  On-Going Projects
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Building Dreams, Creating Reality: Discover our current
                  ventures, where construction meets aspiration, bringing to
                  life spaces that inspire and elevate.
                </Typography>
              </Box>
            </Box>
          </Grid>
          {/* On-Going Projects Text end */}

          {/* On-Going Project Slides start */}
          <Grid item xs={12} sm={7}>
            <Box
              sx={{ position: "relative", height: "100%" }}
              className={
                isVisible ? "animate__animated animate__fadeInRight" : ""
              }
            >
              <Swiper
                style={{
                  backgroundColor: "#fcfcfc",
                  "--swiper-navigation-color": "#A77639",
                  "--swiper-pagination-color": "#A77639",
                  "--swiper-navigation-sides-offset": "20px",
                  boxShadow: "rgba(0, 0, 0, 0.36) 0px 22px 70px 4px",
                  borderRadius: smScreen ? "" : "25px 0 0 25px",
                  paddingTop: smScreen ? "25px" : "",
                }}
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                spaceBetween={50}
                coverflowEffect={{
                  rotate: 5,
                  stretch: 25,
                  depth: 100,
                  modifier: 2.5,
                  slideShadows: false,
                }}
                pagination={{ clickable: true }}
                navigation={true}
                modules={[EffectCoverflow, Pagination, Navigation]}
                className="mySwiper"
              >
                {onGoingProjImg}
              </Swiper>
            </Box>
          </Grid>
          {/* On-Going Project Slides end */}
        </Grid>
      </Box>

      {/* Completed Projects Carousel */}
      <Box id="completed-projects">
        <Grid container spacing={3}>
          {/* Completed Project Text in Small Screen start */}
          {smScreen && (
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: "40px 40px 0",
                }}
              >
                <Box
                  textAlign={"center"}
                  className={
                    isVisible ? "animate__animated animate__fadeInLeft" : ""
                  }
                >
                  <Typography
                    variant="h3"
                    color={"#A77639"}
                    marginBottom="10px"
                    sx={{ fontWeight: "bold" }}
                  >
                    Completed Projects
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Celebrating Success, Embracing Achievement: Explore our past
                    ventures, where construction met aspiration, breathing life
                    into spaces that continue to inspire and elevate.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )}
          {/* Completed Project Text in Small Screen end */}

          {/* Slides of Completed Projects start */}
          <Grid item xs={12} sm={7} paddingY="40px">
            <Box
              sx={{ position: "relative", height: "100%" }}
              className={
                !isVisible
                  ? ""
                  : smScreen
                  ? "animate__animated animate__fadeInRight"
                  : "animate__animated animate__fadeInLeft"
              }
            >
              <Swiper
                style={{
                  backgroundColor: "#fcfcfc",
                  "--swiper-navigation-color": "#A77639",
                  "--swiper-pagination-color": "#A77639",
                  "--swiper-navigation-sides-offset": "20px",
                  boxShadow: "rgba(0, 0, 0, 0.36) 0px 22px 70px 4px",
                  borderRadius: smScreen ? "" : "0 25px 25px 0",
                  paddingTop: smScreen ? "25px" : "",
                }}
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                spaceBetween={50}
                coverflowEffect={{
                  rotate: 5,
                  stretch: 25,
                  depth: 100,
                  modifier: 2.5,
                  slideShadows: false,
                }}
                dir={smScreen ? "" : "rtl"}
                pagination={{ clickable: true }}
                navigation={true}
                modules={[EffectCoverflow, Pagination, Navigation]}
                className="mySwiper"
              >
                {comProjImg}
              </Swiper>
            </Box>
          </Grid>
          {/* Slides of Completed Projects end */}

          {/* Completed Project Text in iPads and Desktop start */}
          {!smScreen && (
            <Grid item sm={5} paddingY="40px">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: "40px",
                }}
              >
                <Box
                  textAlign={"right"}
                  className={
                    isVisible ? "animate__animated animate__fadeInRight" : ""
                  }
                >
                  <Typography
                    variant="h2"
                    color={"#A77639"}
                    marginBottom="10px"
                    sx={{ fontWeight: "bold" }}
                  >
                    Completed Projects
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Celebrating Success, Embracing Achievement: Explore our past
                    ventures, where construction met aspiration, breathing life
                    into spaces that continue to inspire and elevate.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )}
          {/* Completed Project Text in iPads and Desktop start */}
        </Grid>
      </Box>
    </Box>
  );
};

export default Projects;
