import { Route, Routes } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme"
import Topbar from "./components/Topbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import { useEffect, useState } from "react";
import "./App.css"
import InteriorGallery from "./pages/InteriorGallery";
import ExteriorGallery from "./pages/ExteriorGallery";
import PropertyManagement from "./pages/Services/PropertyManagement";
import ComingSoon from "./components/ComingSoon";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate an API call or other async task
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 3000)); // Simulating a 3-second delay
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress sx={{ color: "#a77639" }} /> <span style={{ marginLeft: "10px" }}> Loading...</span>
          </div>
        ) : (
          <>
            <Topbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/gallery/interior" element={<InteriorGallery />} />
              <Route path="/gallery/exterior" element={<ExteriorGallery />} />
              <Route path="/services/property-management" element={<PropertyManagement />} />
              <Route path="/services/approval" element={<ComingSoon />} />
              <Route path="/services/waterproofing-work" element={<ComingSoon />} />
              <Route path="/services/renovation" element={<ComingSoon />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </>
        )}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
