import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { heroImages } from "../data/images";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const HeroSlider = () => {
  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.down("md"));
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));

  let startY = 0;

  const handleTouchStart = (e) => {
    e.preventDefault();
    startY = e.touches[0].clientY;
  };

  const handleTouchMove = (e) => {
    e.preventDefault();

    const deltaY = e.touches[0].clientY - startY;
    window.scrollBy({
      top: -deltaY,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <Box
      sx={{
        "& .swiper": {
          width: " 100%",
          height: smScreen ? "87vh" : mdScreen ? "auto" : "90vh",
          "--swiper-navigation-color": "#A77639",
          "--swiper-pagination-color": "#A77639",
        },
        "& .swiper-slide img": {
          display: smScreen ? "" : "block",
          objectFit: "cover",
          height: "100%",
          width: "100vw",
        },
      }}
      onTouchStart={(e) => handleTouchStart(e)}
      onTouchMove={(e) => handleTouchMove(e)}
    >
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        direction={smScreen ? "vertical" : "horizontal"}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        allowTouchMove={smScreen ? false : mdScreen ? true : false}
        navigation={!smScreen ? true : false}
        modules={[Autoplay, Pagination, Navigation]}
      >
        {heroImages.map((image, index) => (
          <SwiperSlide>
            <Link
              to={image.src}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
            >
              <img src={image.src} alt={image.alt} />
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default HeroSlider;
