import image from "../../assets/projects/srirangam_trichy/1.jpg";
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import "animate.css";

const WhyUs = () => {
  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const smScreen = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();

  // Create a ref for the section
  const sectionRef = useRef(null);

  // Use state to track whether the section is in the viewport
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Function to handle intersection observer callback
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    };

    // Create an Intersection Observer
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Adjust the threshold as needed
    });

    // Observe the section
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    // Cleanup the observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []); // Run only once on component mount

  return (
    <Box
      ref={sectionRef}
      style={{
        backgroundColor: "#A77639",
        color: "#fff",
        padding: "40px",
        fontSize: "32px",
        fontWeight: "bold",
      }}
    >
      <Box sx={{ maxWidth: "1440px", margin: lgScreen ? "auto" : "20px 0" }}>
        <Typography
          variant={smScreen ? "h3" : "h2"}
          sx={{ fontWeight: "bold" }}
          gutterBottom
        >
          Why Choose Us?
        </Typography>
        <Box
          sx={{
            height: "350px",
            width: smScreen ? "100%" : "auto",
            float: smScreen ? "none" : "right",
            marginX: smScreen ? "auto" : "90px",
            marginBottom: smScreen ? "15px" : "",
            boxShadow: "rgba(0, 0, 0, 0.46) 0px 22px 70px 4px",
            borderRadius: "8px",
          }}
          className={isVisible ? "animate__animated animate__bounceInDown" : ""}
        >
          <img
            src={image}
            alt="House"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
        <Typography
          paragraph
          sx={{
            paddingTop: "10px",
            lineHeight: "1.6",
            fontWeight: "bold"
          }}
          className={isVisible ? "animate__animated animate__fadeInLeft" : ""}
        >
          Choose Eshwar Signatures for an unparalleled home-building experience.
          Our commitment to excellence and customer satisfaction sets us apart
          in the real estate and construction industry.
        </Typography>
        <Typography
          paragraph
          sx={{
            paddingTop: "10px",
            lineHeight: "1.6",
            fontWeight: "bold"
          }}
          className={isVisible ? "animate__animated animate__fadeInLeft" : ""}
        >
          <span style={{ color: "black" }}>
            <strong>Bespoke Solutions:</strong>
          </span>{" "}
          We specialize in tailoring solutions to meet your unique lifestyle and
          housing preferences. Your dream home is crafted with precision and
          attention to detail.
        </Typography>
        <Typography
          paragraph
          sx={{
            paddingTop: "10px",
            lineHeight: "1.6",
            fontWeight: "bold"
          }}
          className={isVisible ? "animate__animated animate__fadeInLeft" : ""}
        >
          <span style={{ color: "black" }}>
            <strong>Artistry and Functionality:</strong>
          </span>{" "}
          Beyond conventional construction, we blend artistry and functionality
          into every project, raising the bar for design and construction
          standards.
        </Typography>
        <Typography
          paragraph
          sx={{
            paddingTop: "10px",
            lineHeight: "1.6",
            fontWeight: "bold"
          }}
          className={isVisible ? "animate__animated animate__fadeInLeft" : ""}
        >
          <span style={{ color: "black" }}>
            <strong>Established Legacy:</strong>
          </span>{" "}
          Since 2003, our journey has been marked by experience and expertise.
          Trust us to turn your vision of a dream home into a reality backed by
          years of successful projects.
        </Typography>
        <Typography
          paragraph
          sx={{
            paddingTop: "10px",
            lineHeight: "1.6",
            fontWeight: "bold"
          }}
          className={isVisible ? "animate__animated animate__fadeInLeft" : ""}
        >
          <span style={{ color: "black" }}>
            <strong>Unprecedented Growth:</strong>
          </span>{" "}
          Our exponential growth is a testament to our founder's dedication and
          our commitment to exceeding client expectations. Your satisfaction is
          our success.
        </Typography>
        <Typography
          paragraph
          sx={{
            paddingTop: "10px",
            lineHeight: "1.6",
            fontWeight: "bold"
          }}
          className={isVisible ? "animate__animated animate__fadeInLeft" : ""}
        >
          <span style={{ color: "black" }}>
            <strong>Comprehensive Services:</strong>
          </span>{" "}
          Beyond construction, we offer a range of services including Project
          Management, Vasthu Consultancy, Legal Assistance in Real Estate,
          demonstrating our commitment to comprehensive customer support.
        </Typography>
        <Typography
          paragraph
          sx={{
            paddingTop: "10px",
            lineHeight: "1.6",
            fontWeight: "bold"
          }}
          className={isVisible ? "animate__animated animate__fadeInLeft" : ""}
        >
          Discover the Eshwar Signatures advantage and let us transform your
          vision into the home of your dreams.
        </Typography>
        <Button
          variant="contained"
          sx={{
            background: "#FFF",
            color: "#a77639",
            "&:hover": {
              background: "#e0e0e0",
            },
            fontWeight: "bold",
          }}
          onClick={() => navigate("/about")}
        >
          About Us
        </Button>
      </Box>
    </Box>
  );
};

export default WhyUs;
