import React from "react";
import { Typography } from "@mui/material";

const SectionHeader = ({ variant, textAlign, color, padding, children }) => {
  return (
    <Typography
      variant={variant}
      fontWeight={600}
      textAlign={textAlign}
      padding={padding}
      color={color || "#A77639"}
      textTransform="capitalize"
    >
      {children}
    </Typography>
  );
};

export default SectionHeader;
