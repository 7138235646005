import img1 from "../assets/carousel_images/1.jpg";
import img2 from "../assets/carousel_images/2.jpg";
import img3 from "../assets/carousel_images/3.jpg";
import img4 from "../assets/carousel_images/4.jpg";
import img5 from "../assets/carousel_images/5.jpg";
import img6 from "../assets/carousel_images/6.jpg";

// Exterior Images
import chidambaram_1 from "../assets/projects/chidambaram/1.jpg";
import chidambaram_2 from "../assets/projects/chidambaram/2.jpg";
import yogadatra_ex1 from "../assets/projects/yogadatra_kolathur/exterior/1.jpg";
import yogadatra_ex2 from "../assets/projects/yogadatra_kolathur/exterior/2.jpg";
import choolaimedu from "../assets/projects/choolaimedu/1.jpg";
import ponneri_1 from "../assets/projects/ponneri_project/project_1.jpg";
import ponneri_2 from "../assets/projects/ponneri_project/project_2.jpg";
import srirangam_1 from "../assets/projects/srirangam_trichy/1.jpg";
import arumbakkam_1 from "../assets/projects/srirangas_arumbakkam/1.jpg";
import arumbakkam_2 from "../assets/projects/srirangas_arumbakkam/2.jpg";
import arumbakkam_3 from "../assets/projects/srirangas_arumbakkam/3.jpg";
import karnodai from "../assets/projects/karnodai.jpg";
import kundrathur from "../assets/projects/kundrathur.jpg";

// Interior Images
import yogadatra_1 from "../assets/projects/yogadatra_kolathur/interior/1.jpg";
import yogadatra_2 from "../assets/projects/yogadatra_kolathur/interior/2.jpg";
import yogadatra_3 from "../assets/projects/yogadatra_kolathur/interior/3.jpg";
import yogadatra_4 from "../assets/projects/yogadatra_kolathur/interior/4.jpg";
import yogadatra_5 from "../assets/projects/yogadatra_kolathur/interior/5.jpg";
import yogadatra_6 from "../assets/projects/yogadatra_kolathur/interior/6.jpg";
import yogadatra_7 from "../assets/projects/yogadatra_kolathur/interior/7.jpg";
import yogadatra_8 from "../assets/projects/yogadatra_kolathur/interior/8.jpg";
import yogadatra_9 from "../assets/projects/yogadatra_kolathur/interior/9.jpg";
import yogadatra_10 from "../assets/projects/yogadatra_kolathur/interior/10.jpg";
import yogadatra_11 from "../assets/projects/yogadatra_kolathur/interior/11.jpg";
import yogadatra_12 from "../assets/projects/yogadatra_kolathur/interior/12.jpg";
import yogadatra_13 from "../assets/projects/yogadatra_kolathur/interior/13.jpg";
import yogadatra_14 from "../assets/projects/yogadatra_kolathur/interior/14.jpg";
import yogadatra_15 from "../assets/projects/yogadatra_kolathur/interior/15.jpg";
import yogadatra_16 from "../assets/projects/yogadatra_kolathur/interior/16.jpg";
import yogadatra_17 from "../assets/projects/yogadatra_kolathur/interior/17.jpg";
import yogadatra_18 from "../assets/projects/yogadatra_kolathur/interior/18.jpg";
import yogadatra_19 from "../assets/projects/yogadatra_kolathur/interior/19.jpg";
import yogadatra_20 from "../assets/projects/yogadatra_kolathur/interior/20.jpg";
import yogadatra_21 from "../assets/projects/yogadatra_kolathur/interior/21.jpg";
import yogadatra_22 from "../assets/projects/yogadatra_kolathur/interior/22.jpg";

// Home Page Carousel Images
const heroImages = [
    {
        src: img1,
        alt: "Beautiful Living Room",
    },
    {
        src: img2,
        alt: "Cozy Living Room",
    },
    {
        src: img3,
        alt: "Modern Living Room",
    },
    {
        src: img4,
        alt: "Stylish Kitchen View",
    },
    {
        src: img5,
        alt: "Elegant Kitchen View",
    },
    {
        src: img6,
        alt: "Comfortable Bed Room",
    },
];

// Interior Images
const interior = [
    {
        src: yogadatra_1,
        alt: "Interior Image"
    },
    {
        src: yogadatra_2,
        alt: "Interior Image"
    },
    {
        src: yogadatra_3,
        alt: "Interior Image"
    },
    {
        src: yogadatra_4,
        alt: "Interior Image"
    },
    {
        src: yogadatra_5,
        alt: "Interior Image"
    },
    {
        src: yogadatra_6,
        alt: "Interior Image"
    },
    {
        src: yogadatra_7,
        alt: "Interior Image"
    },
    {
        src: yogadatra_8,
        alt: "Interior Image"
    },
    {
        src: yogadatra_9,
        alt: "Interior Image"
    },
    {
        src: yogadatra_10,
        alt: "Interior Image"
    },
    {
        src: yogadatra_11,
        alt: "Interior Image"
    },
    {
        src: yogadatra_12,
        alt: "Interior Image"
    },
    {
        src: yogadatra_13,
        alt: "Interior Image"
    },
    {
        src: yogadatra_14,
        alt: "Interior Image"
    },
    {
        src: yogadatra_15,
        alt: "Interior Image"
    },
    {
        src: yogadatra_16,
        alt: "Interior Image"
    },
    {
        src: yogadatra_17,
        alt: "Interior Image"
    },
    {
        src: yogadatra_18,
        alt: "Interior Image"
    },
    {
        src: yogadatra_19,
        alt: "Interior Image"
    },
    {
        src: yogadatra_20,
        alt: "Interior Image"
    },
    {
        src: yogadatra_21,
        alt: "Interior Image"
    },
    {
        src: yogadatra_22,
        alt: "Interior Image"
    },
]

// Exterior Images
const exterior = [
    {
        src: chidambaram_1,
        alt: "Chidambaram Villa Exterior"
    },
    {
        src: chidambaram_2,
        alt: "Chidambaram Villa Exterior"
    },
    {
        src: yogadatra_ex1,
        alt: "Kolathur Project Exterior"
    },
    {
        src: yogadatra_ex2,
        alt: "Kolathur Project Exterior"
    },
    {
        src: choolaimedu,
        alt: "Choolaimedu Flats Exterior"
    },
    {
        src: ponneri_1,
        alt: "Ponneri Project Exterior"
    },
    {
        src: ponneri_2,
        alt: "Ponneri Project Exterior"
    },
    {
        src: srirangam_1,
        alt: "Srirangam Project Exterior"
    },
    {
        src: arumbakkam_1,
        alt: "Arumbakkam Project Exterior"
    },
    {
        src: arumbakkam_2,
        alt: "Arumbakkam Project Exterior"
    },
    {
        src: arumbakkam_3,
        alt: "Arumbakkam Project Exterior"
    },
    {
        src: karnodai,
        alt: "Karnodai Project Exterior"
    },
    {
        src: kundrathur,
        alt: "Kundrathur Project Exterior"
    },
]

export { heroImages, interior, exterior };