import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import eshwaramoorthy from "../../assets/Eshwaramoorthy.png";
import Footer from "../../components/Footer";
import ScrollToTopButton from "../../components/ScrollToTopButton";
import WhatsAppButton from "../../components/WhatsAppButton";

const About = () => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <main>
      <Box
        component="section"
        sx={{
          background: "#A77639",
          color: "#fff",
          marginTop: "50px",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh", // Set the container to at least 100% of the viewport height
        }}
      >
        <Box
          component="article"
          sx={{
            flex: 1,
            margin: "80px 40px",
            "@media (min-width: 600px)": {
              margin: "80px 40px",
            },
            "@media (min-width: 960px)": {
              margin: "40px auto",
              maxWidth: "960px",
            },
            "@media (min-width: 1450px)": {
              margin: "80px auto",
              maxWidth: "1440px",
            },
          }}
        >
          <Typography
            variant="h2"
            sx={{
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            About Us
          </Typography>

          {/* Image */}
          <Box
            component="figure"
            sx={{
              maxWidth: "300px",
              float: "left",
              marginRight: "20px",
              borderRadius: "8px",
              "@media (max-width: 600px)": {
                maxWidth: "100%",
                margin: 0,
                float: "none",
              },
            }}
          >
            <img
              src={eshwaramoorthy}
              alt="Eshwaramoorthy - Founder of Eshwar Signatures"
              style={{ width: "100%", borderRadius: "15px" }}
            />
          </Box>

          {/* Content */}
          <Typography
            variant="body1"
            paragraph
            sx={{
              paddingTop: "10px",
              textIndent: smScreen ? "20px" : "50px",
              lineHeight: "1.6",
            }}
          >
            <span style={{ fontSize: "24px" }}>C</span>alling on the journey in
            this proforma,{" "}
            <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
              Mr. Eshwaramoorthy
            </span>{" "}
            crossed hurdles to establish this firm. He started his run as an
            aspiring entrepreneur in this field of business and turned out to
            make profound{" "}
            <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
              tailor-made deliverables
            </span>{" "}
            to the clients, garnering exclusive attribution. This leap was
            herculean for someone with no name to develop an institutional firm.
          </Typography>

          <Typography
            variant="body1"
            paragraph
            sx={{ textIndent: smScreen ? "20px" : "50px", lineHeight: "1.6" }}
          >
            <span style={{ fontSize: "24px" }}>I</span>nto the art of making
            able crafts, etched and carved deeply, Mr. Eshwaramoorthy turned his
            interests into{" "}
            <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
              aesthetics and ergonomics
            </span>
            , an alter version of craftsmanship that runs this industry. With
            this developed interest turned into the vision, Mr. Eshwaramoorthy{" "}
            <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
              started this firm, Eshwar Signatures, in 2003.
            </span>{" "}
            Since then, the firm has achieved exponential growth with the
            persistence of our founder and excelling expectancy of the clients,
            outliving the idea that agreements made are uncompromised.
          </Typography>

          <Typography
            variant="body1"
            paragraph
            sx={{ textIndent: smScreen ? "20px" : "50px", lineHeight: "1.6" }}
          >
            <span style={{ fontSize: "24px" }}>F</span>rom humble beginnings to
            a team with{" "}
            <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
              strong architectural and engineering expertise
            </span>
            , we have come a long way carrying the vision forward without any
            deviation. With attributed reputations from stakeholders, we
            expanded the business{" "}
            <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
              in 2020 as Promoters and Project Management Consultants
            </span>{" "}
            and also expanded in areas of{" "}
            <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
              Vasthu Consultancy and Legal Assistance in Real Estate.
            </span>
          </Typography>

          <Typography
            variant="body1"
            paragraph
            sx={{ textIndent: smScreen ? "20px" : "50px", lineHeight: "1.6" }}
          >
            <span style={{ fontSize: "24px" }}>E</span>xceeding the expectations
            of the clients with unwavering satisfaction and providing
            deliverables with fine investments to the vision incorporated will
            be our topmost priority for Eshwar Signatures as long as this
            journey takes...
          </Typography>
        </Box>
        <Footer />
        {/* Scroll to top button */}
        <ScrollToTopButton />

        {/* WhatsApp Button */}
        {smScreen && <WhatsAppButton />}
      </Box>
    </main>
  );
};

export default About;
