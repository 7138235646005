import { createTheme } from "@mui/material/styles";
import { darken, lighten } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF", // White
      dark: darken("#FFFFFF", 0.2), // Darker shade
      light: lighten("#FFFFFF", 0.2), // Lighter shade
    },
    secondary: {
      main: "#000000", // Black
      dark: darken("#000000", 0.2), // Darker shade
      light: lighten("#000000", 0.2), // Lighter shade
    },
    background: {
      default: "#FFFFFF", // White
      dark: darken("#FFFFFF", 0.1), // Darker shade
      light: lighten("#FFFFFF", 0.1), // Lighter shade
    },
    text: {
      primary: "#000000", // Black
      dark: darken("#000000", 0.1), // Darker shade
      light: lighten("#000000", 0.1), // Lighter shade
    },
  },
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
    fontSize: 12, // Default font-size
    h1: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontSize: "2.5em" // 40px  // 1rem = 16px
    },
    h2: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontSize: "2rem" // 32px
    },
    h3: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontSize: "1.5rem" // 24px
    },
    h4: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontSize: "1.25rem" // 20px
    },
    h5: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontSize: "1rem" // 16px
    },
    h6: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontSize: "0.875rem" // 14px
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // color: "black",
          "&.Mui-focused": {
            color: "black", // Set focused label color
          },
          "&.Mui-error": {
            color: "#d32f2f", // Set error focused label color
          },
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& $notchedOutline": {
            borderColor: "black", // Set default border color
          },
          "&:hover $notchedOutline": {
            borderColor: "black", // Set border color on hover
          },
          "&.Mui-focused $notchedOutline": {
            borderColor: "black", // Set border color when focused
          },
        },
      },
    },
  }
});

export default theme;

