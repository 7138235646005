import React from "react";
import { Box, Typography } from "@mui/material";

const ComingSoon = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Typography
        variant="h2"
        sx={{
          fontWeight: "600",
          color: "#FFC47E",
        }}
      >
        Page Coming Soon...
      </Typography>
      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
        }}
      >
        Thank you for your pateince!
      </Typography>
    </Box>
  );
};

export default ComingSoon;
