// ScrollToTopButton.jsx
import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    // Set visibility based on scroll position
    setIsVisible(window.scrollY > 0);
  };

  useEffect(() => {
    // Add scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array to run the effect only once on mount

  return (
    <IconButton
      sx={{
        position: "fixed",
        bottom: 16,
        right: 16,
        backgroundColor: "#A77639",
        color: "#ffffff",
        "&:hover": {
          backgroundColor: "#825f2d",
          color: "#ffffff",
        },
        display: isVisible ? "block" : "none", // Show or hide based on visibility state
      }}
      onClick={handleScrollToTop}
    >
      <KeyboardArrowUpIcon sx={{ fontSize: "28px" }} />
    </IconButton>
  );
};

export default ScrollToTopButton;
