// WhatsAppButton.jsx
import React from "react";
import { IconButton } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const WhatsAppButton = ({ phoneNumber }) => {
  const handleWhatsAppClick = () => {
    // Construct the WhatsApp URL with the phone number
    const whatsappURL = `https://wa.me/+919566098707`;

    // Open the URL in a new tab or window
    window.open(whatsappURL, "_blank");
  };

  return (
    <IconButton
      sx={{
        position: "fixed",
        bottom: 16,
        left: 16,
        backgroundColor: "#25d366",
        "&:hover": {
          backgroundColor: "#128C7E",
        },
        fontSize: "32px",
        zIndex: 1000,
      }}
      onClick={handleWhatsAppClick}
    >
      <WhatsAppIcon sx={{fontSize: "32px"}} />
    </IconButton>
  );
};

export default WhatsAppButton;
